/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  body {
    margin: 0;
    font-family: 'Quicksand', sans-serif !important; //important is needed because component libraries have defined fonts for other systems
    font-weight: 400;
  }

  .grecaptcha-badge {
    bottom: 64px !important;
  }

  span.customTableCell {
    padding-left: 0;
    height: 100%;
  }

  #szafirsdk-errors-container .content {
    font-family: 'Roboto', sans-serif !important;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper {
    padding: 1rem;
  }

  .layout-wrapper {
    @apply flex flex-col box-border h-screen
  }

  .page-content {
    //@media (min-width: 0px) and (max-width: 899px) {
    //  margin-bottom: 80px
    //}
    //@media (min-width: 900px) and (max-width: 1199px) {
    //  margin-bottom: 65px
    //}
    //margin-bottom: 50px;
    @apply flex flex-col grow mb-0 flex-auto
  }

  .managmentPage {
    @apply flex flex-col gap-2
  }

  .managmentPageResponsive {
    @media (min-width: 0px) and (max-width: 360px) {
      max-width: 100%;
    }

    @media (min-width: 360px) {
      max-width: calc(100vw - 250px);
    }

    @media (min-width: 768px) {
      max-height: calc(100vh - 146px);
    }

    @apply flex-auto max-h-fit h-fit overflow-y-scroll;
    max-width: calc(100vw - 250px);
  }

  .buttonFixed {
    @media (min-width: 0px) and (max-width: 768px) {
      position: relative;
      bottom: auto;
      right: auto;
    }

    @media (min-width: 769px) {
      bottom: 100px;
      right: 50px;
    }
  }

  .buttonFixedWithMargin {
    @media (min-width: 0px) and (max-width: 768px) {
      position: relative;
      bottom: auto;
      right: auto;
    }
    
    @media (min-width: 769px) {
      position: fixed;
      bottom: 0;
      right: 27px;
      width: 100%;
      height: 165px;
      padding-top: 15px;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
    margin: 0.25rem
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #F4F4F4
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 125, 0, 1);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 165, 0, 1);
  }
 
  @-moz-document url-prefix() {
    * {
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 125, 0, 1) #F4F4F4;
    }
}

  .pre-line-typography {
    white-space: pre-line;
  }

  .company-ellipsis {
    @apply inline-block mx-auto my-0 whitespace-nowrap overflow-hidden leading-5 text-ellipsis;
  }

  .ellipsis-w {
    @apply w-[5rem] lg: w-[8rem] xl:w-[17rem];
  }

  .ellipsis-w-modal {
    @apply w-[10rem];
  }

  .dialogWindow {
    margin: 0;
    width: 18.75rem;
    height: 11.875rem;
  }

  .btn-xl {
    @apply w-[11rem];
  }

  .btn-md {
    @apply w-[7.875rem];
  }

  .timeInterval {
    @apply w-24 h-10 m-2;
  }

  .TextFieldNumberWithoutArrows input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    display: none;
  }

  .uploadPaper {
    @apply min-w-115 w-115 min-h-85 p-6 mx-auto inline-block;
  }

  .uploadImage {
    @apply mb-4;
  }

  .invoice-action-wrapper {
    display: flex;
    align-items: center;

    .ocr-icon {
      width: 14px;
      height: 14px
    }
  }

  .uploadedList {
    @apply min-h-full h-36 overflow-x-hidden overflow-y-scroll my-6 py-2;
  }

  .btn-outlined-loading-content {
    @apply text-slate-300
  }

  .activeDropZoneContainer {
    @apply flex flex-1 my-[20px] mx-auto min-h-[260px] flex-col items-center justify-center content-center p-[48px] border-2 rounded-2xl border-dashed border-slate-300 outline-0 text-black bg-white;
    transition: border .24s ease-in-out;
  }

  .blockedDropZoneContainer {
    @apply flex flex-1 my-[20px] mx-auto min-h-[260px] flex-col items-center justify-center content-center p-[48px] border-2 rounded-2xl border-dashed outline-0 border-red-600 text-black bg-white;
    cursor: not-allowed;
    transition: border .24s ease-in-out;
  }

  input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
    padding-left: 14px;
  }

  #launcher,
  #webWidget {
    @media (min-width: 0px) and (max-width: 899px) {
      margin-bottom: 75px !important;
    }

    @media (min-width: 900px) and (max-width: 1199px) {
      margin-bottom: 55px !important;
    }

    margin-bottom: 55px !important;
  }
}